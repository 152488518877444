<template>
  <div class="option flex items-center border-b border-gray-700 px-3 pt-2 pb-2">
    <div
      v-if="mesh.uvs.length > 1"
      class="flex-auto ml-1"
    >
      <!-- Multiple UV Maps on the Mesh -->
      <template v-for="uv in mesh.uvs">
        <span
          class="px-2 pb-1 bg-gray-700 m-1 rounded-full text-gray-200 cursor-pointer hover:bg-gray-600 hover:text-white"
          @click.prevent="highlight(mesh, uv)"
        >
          <fa-icon
            icon="circle"
            size="sm"
            style="margin: -2px"
            :class="uvDotClass(uv)"
          /> {{ mesh.name }}:{{ uv }}
        </span>
      </template>
    </div>
    <div
      v-else
      class="flex-auto ml-1"
    >
      <!-- Single UV map on the Mesh -->
      <div class="inline-block">
        <span
          class="px-2 pb-1 bg-gray-700 m-1 rounded-full text-gray-200 cursor-pointer hover:bg-gray-600 hover:text-white"
          @click.prevent="highlight(mesh)"
        >
          <fa-icon
            icon="circle"
            size="sm"
            style="margin: -2px"
            :class="uvDotClass(mesh.uvs[0])"
          /> {{ mesh.name }}
        </span>
      </div>
    </div>

    <div class="flex-1 text-right">
      <v-popover>
        <button
          class="btn btn-no-style focus:outline-none focus:text-white text-gray-600 rounded-full py-1"
          @click.prevent
        >
          <fa-icon icon="ellipsis" />
        </button>

        <template slot="popover">
          <ul class="text-white text-sm leading-loose">
            <li>
              <a
                href="#"
                class="flex justify-between items-center hover:underline"
              >
                Rename "uv" <fa-icon
                  icon="pencil"
                  class="pl-1"
                />
              </a>
            </li>
            <li>
              <a
                href="#"
                class="flex justify-between items-center hover:underline"
              >
                Rename "uv2" <fa-icon
                  icon="pencil"
                  class="pl-1"
                />
              </a>
            </li>
          </ul>
        </template>
      </v-popover>
    </div>
  </div>
</template>
<script>
const InputWrapper = () => import('@components/editor/module/inputs/InputWrapper')

export default {
  name: 'MappingSingleMesh',

  components: {
    InputWrapper
  },

  props: ['mesh', 'highlighted'],

  computed: {

    highlightedUv () {
      if (this.highlighted.indexOf(this.mesh.name) > -1) return true
      return false
    }

  },

  methods: {
    uvDotClass (uv) {
      if (this.highlightedUv) return 'text-white'
      return 'text-gray-800'
    },

    highlight (mesh, uv) {
      this.$emit('highlight', mesh.name, uv)
    },

    onInputChange (value) {
      console.log('Input change event', value)
    }
  }
}
</script>
